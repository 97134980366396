/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-return-assign */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import logo from '../../assets/images/logotipo-unindo-sonhos.png';

import ecommerceServices from '../../services/ecommerce';
import walletServices from '../../services/wallet';

import { history } from '../../helpers/history';

import '../Register/style.scss';
import './style.scss';
import PaymentStep from './steps/payment';
import AddressStep from './steps/address';
import TermsStep from './steps/terms';
import account from '../../services/account';

import VisaBrand from '../../assets/images/bandeira-visa.svg';
import MasterCardBrand from '../../assets/images/bandeira-mastercard.svg';
import EloCardBrand from '../../assets/images/bandeira-elo.svg';
import AmexCardBrand from '../../assets/images/bandeira-amex.svg';

export async function handleCheckoutStep(setCheckoutData, setStep, setLoading) {
  setLoading(true);

  const [customer, customerMe] = await Promise.all([
    await account.getOnboardProfile().then(async (response) => {
      setCheckoutData((state) => ({
        ...state,
        customer: response.data,
      }));

      return response.data;
    }),
    await account.getMe().then(async (response) => {
      setCheckoutData((state) => ({
        ...state,
        customer_me: response.data?.customer,
      }));

      return response.data?.customer;
    }),
  ]);

  if (!customer || !customerMe) {
    window.location.href = `${process.env.REACT_APP_ON_BOARDING_URL}/login?redirect_to=${window.location.origin}/checkout`;
    return;
  }

  // if (!customer?.cpf_verified_at) {
  //   window.location.href = `${process.env.REACT_APP_ON_BOARDING_URL}/validate/user?validationGroup=checkout`;
  //   return;
  // }

  if (!customer?.address) {
    setStep('address');
    setLoading(false);
    return;
  }

  if (customerMe?.need_accept_term) {
    setStep('terms');
    setLoading(false);
    return;
  }

  setStep('payment');

  setLoading(false);
}

export function Checkout() {
  const cartKey = localStorage.getItem('cartKey');
  const [step, setStep] = useState('address');
  const [loading, setLoading] = useState(true);
  const [withWallet, setWithWallet] = useState(false);

  const [checkoutData, setCheckoutData] = useState({
    customer: null,
    cards: null,
    sessionDefaultCard: null,
    founds: 0,
    cart: null,
    methods: null,
    processedInfo: null,
  });

  async function handleInit() {
    if (!checkoutData.customer) {
      await handleCheckoutStep(setCheckoutData, setStep, setLoading);
    }
  }

  async function getBalanceFounds() {
    await walletServices.getBalance().then((response) => (
      setCheckoutData((state) => ({
        ...state,
        founds: response.data.founds,
      }))
    ));
  }

  async function getCheckoutInfo() {
    await ecommerceServices.checkout({
	    key: cartKey,
	    withWallet,
    }).then((response) => {
      setCheckoutData((state) => ({
        ...state,
        cart: response.data,
      }));

      setCheckoutData((state) => ({
        ...state,
        methods: response.data?.payments_methods,
      }));
    });
  }

  async function paymentRequests() {
    setLoading(true);
    await Promise.all([
      getCheckoutInfo(),
      getCreditCardInfo(),
      getBalanceFounds(),
    ]);
	  setStep('payment');
	  setLoading(false);
  }

  useEffect(() => {
    if (!cartKey) {
      setStep('payment');
      setLoading(false);

      return;
    }

    const imagesPreload = [VisaBrand, MasterCardBrand, EloCardBrand, AmexCardBrand];
    imagesPreload.forEach((image) => {
      const newImage = new Image();
      newImage.src = image;
      window[image] = newImage;
    });

    handleInit();
  }, []);

  useEffect(() => {
    if (step === 'payment' && cartKey) {
      paymentRequests();
    }
  }, [step]);

  useEffect(() => {
    if (step === 'payment' && cartKey) {
      getCheckoutInfo();
    }
  }, [withWallet]);

  async function getCreditCardInfo() {
    // if (!checkoutData?.customer?.face_match_verified_at) return;

    const params = {
      search: 'status:approved',

      sortedBy: 'desc',
      withOrderBy: '',
      orderBy: 'is_default',
      limit: 0,
    };

    await account.getCards(params).then(async (response) => {
      const sessionDefaultCard = response.data.find((card) => card.is_default);
      setCheckoutData((state) => ({
        ...state,
        cards: response.data,
        sessionDefaultCard: sessionDefaultCard?.id,
      }));
    });
  }

  return (
    <main id="checkout">
      <div id="steps">
        <figure onClick={() => history.push('/')}>
          <img src={logo} alt="Logotipo da Unindo Sonhos" />
        </figure>
        <section id="checkout-step-box">
          {loading && <span className="loading"><CircularProgress /></span>}
          {!loading && step === 'address' && (
            <AddressStep
              setLoading={setLoading}
              loading={loading}
              processedInfo={checkoutData.processedInfo}
              order={checkoutData.order}
              setStep={setStep}
              setCheckoutData={setCheckoutData}
            />
          )}
          {!loading && step === 'terms' && (
            <TermsStep
              loading={loading}
              setStep={setStep}
              setLoading={setLoading}
              setCheckoutData={setCheckoutData}
            />
          )}
          {!loading && step === 'payment' && (
            <PaymentStep
              checkoutData={checkoutData}
              setCheckoutData={setCheckoutData}
              setStep={setStep}
              loading={loading}
              setLoading={setLoading}
              withWallet={withWallet}
              setWithWallet={setWithWallet}
              getCheckoutInfo={getCheckoutInfo}
              updateCardList={getCreditCardInfo}
            />
          )}
        </section>
      </div>
    </main>
  );
}

export default Checkout;
