/* eslint-disable default-param-last */
import api from './api';

const cartService = {
  async getCart(cartKey) {
    try {
      const url = `/cart?cartKey=${cartKey}`;
      const { data } = await api.get(url);
      return data;
    } catch (error) {
      return false;
    }
  },
  async destroyCart(cartKey) {
    try {
      const url = `/cart/destroy?cartKey=${cartKey}`;
      const { data } = await api.delete(url);
      return data;
    } catch (error) {
      return false;
    }
  },

  async removeItems(rowID, cartKey) {
    try {
      const url = `/cart/remove?cartKey=${cartKey}&items=${encodeURI(rowID)}`;
      const { data } = await api.delete(url);
      return data;
    } catch (error) {
      return false;
    }
  },
  async updateItem(rowID, cartKey, qty) {
    try {
      const bodyUpdate = {
        cartKey,
        items: {
          [rowID]: {
            rowId: rowID,
            values: {
              qty,
            },
          },
        },
      };
      const url = '/cart/update';
      const { data } = await api.post(url, bodyUpdate);
      return data;
    } catch (error) {
      return error.response.data;
    }
  },
  async addItemToCart(id = 1, qty = 1, cartKey = '', amount = 0) {
    try {
      const url = '/cart/add';
      const { data } = await api.post(url, null, {
        params: {
          ...(amount > 0
            ? { amount, wallet_topup: true }
            : {
              id,
              qty,
              cartKey,
            }
          ),
        },
      });
      return data;
    } catch (error) {
      return error.response.data;
    }
  },
};
export default cartService;
