import api from './api';

const ecommerce = {
  async creatAPICart(cart) {
    try {
      const _input = cart && cart.wallet_topup
        ? { wallet_topup: true, amount: cart.amount }
        : { cartKey: '', items: cart };

      const { data } = await api.post('/cart/add', _input);
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async checkout({ key, withWallet }) {
    try {
      const params = {
        // ...(withWallet && { use_wallet: 1 }),
        use_wallet: 1,
      };

      const { data } = await api.get(`/checkout/v2/${key}`, { params });
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async doPayment(key, checkoutData) {
    try {
      const { data } = await api.post(`/checkout/v2/${key}/process`, checkoutData);
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async getInstallments(cardData) {
    try {
      const { data } = await api.get('/gateway/creditcard/installments', { params: cardData });
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
};
export default ecommerce;
