/* eslint-disable linebreak-style */
/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import './style.scss';
import useRedis from '../../hooks/useRedis';

export default function RedirectPage() {
  const { search } = useLocation();
  const queryParams = queryString.parse(search);

  useEffect(() => {
    if (queryParams.h) {
      useRedis(queryParams.h).then((data) => {
        localStorage.setItem('unindo_sonhos_data', data);
        window.location.href = queryParams.redirect_to;
      });
    }

    if (queryParams.action === 'logout') {
      localStorage.removeItem('unindo_sonhos_data');
    }
  }, [search]);

  return null;
}
